/*
 * "Web Alert Reason Code Service":
 * The service checks if a trip has alert reason codes that prohibits passenger(s) to check-in.
 */

import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { Response } from '../models/response.model';
import { Trip } from '../models/trip.model';
import { ALERTS, Constants } from '../constants/ha-constants';
import { Segment } from '../models/segment.model';

interface AlertError {
  error: string;
  requestData: Response<Trip>;
  airportTime?: any;
  origin?: string;
  destination?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertReasonCodeService {
  trip: Response<Trip>;

  constructor(
    private helperService: HelperService
    ) {}

  public checkAllAlert(trip: Response<Trip>) {
    // set variables for returning alert errors
    this.trip = trip;

    // get all segment entries & alertCodes array
    let segmentEntries = this.helperService.getSegmentEntries(trip);
    let alertCodes = this.getAlertCodes(segmentEntries);
    alertCodes = alertCodes.filter(code => code !== ALERTS.unaccompaniedMinorAlertReasonCode);

    const isInternational = segmentEntries.some(entry => entry.marketType === Constants.marketTypeInternational);

    // if able to check-in, only check airport assistance alert
    if (segmentEntries.some(entry => entry.canAllPassengersCheckIn !== false)) {
      // filter for only segment that can be checked-in
      segmentEntries = segmentEntries.filter(entry => entry.canAllPassengersCheckIn === true);
      alertCodes = this.getAlertCodes(segmentEntries);

      return this.checkAirportAssistanceAlert(alertCodes);
    }

    if (!!alertCodes) {
      // check cases for alert codes
      const alertLateCheckIn = this.checkLateCheckInAlert(alertCodes);
      const alertEarlyCheckIn = this.checkEarlyCheckInAlert(alertCodes);
      const alertInhibited = this.checkInhibitedAlert(alertCodes);
      const alertAirportAssistance = this.checkAirportAssistanceAlert(alertCodes);
      const alertFlightCancelled = this.checkFlightCancelledAlert(alertCodes);
      const alertMinorPnr = this.checkMinorPnrAlert(alertCodes);
      const alertDefault = this.getFirstAlert(alertCodes, isInternational);

      // return alert error object
      if (!!alertLateCheckIn) { return alertLateCheckIn; }
      if (!!alertEarlyCheckIn) { return alertEarlyCheckIn; }
      if (!!alertInhibited) { return alertInhibited; }
      if (!!alertAirportAssistance) { return alertAirportAssistance; }
      if (!!alertFlightCancelled) { return alertFlightCancelled; }
      if (!!alertMinorPnr) { return alertMinorPnr; }
      if (!!alertDefault) { return alertDefault; }
    }

    return;
  }

  private getAlertCodes(segmentEntries: Segment[]) {
    let alertCodeArray = [];
    segmentEntries.forEach(entry => {
      entry.details.forEach(detail =>
        detail.flightDetails.forEach(flightDetail => {
          if ((flightDetail.alertReasonCode === ALERTS.unaccompaniedMinorAlertReasonCode || (flightDetail.marketType === Constants.marketTypeInternational && flightDetail.alertReasonCode === ALERTS.regulatoryDetailsInhibition))) {
            entry.canAllPassengersCheckIn = true;
          }
          else if (!!flightDetail.alertReasonCode?.length) {
            alertCodeArray = alertCodeArray.concat(flightDetail.alertReasonCode.split(','));
          }
        })
    );

  });
    return alertCodeArray;
  }

  private checkLateCheckInAlert(alertCodeArray: Array<string>): AlertError {
    const alert = ALERTS.lateCheckInAlert.find(alertCode => alertCodeArray.includes(alertCode));
    if (!!alert) {
      return { error: alert, requestData: this.trip };
    }
    return;
  }

  private checkMinorPnrAlert(alertCodeArray: Array<string>): AlertError {
    const alert = alertCodeArray.includes(ALERTS.unaccompaniedMinorReasonCode);
    if (!!alert) {
      return { error: ALERTS.unaccompaniedMinorReasonCode, requestData: this.trip };
    }
    return;
  }

  private checkEarlyCheckInAlert(alertCodeArray: Array<string>): AlertError {
    const alert = ALERTS.earlyCheckInAlert.find(alertCode => alertCodeArray.includes(alertCode));
    if (!!alert) {
      let airportTime: any = new Date(this.trip.results[0].flights.entries[0].scheduledDeparture.airportDateTimeString);
      airportTime = airportTime.setDate(airportTime.getDate() - 1);
      return { error: alert, requestData: this.trip, airportTime: airportTime };
    }
    return;
  }

  private checkFlightCancelledAlert(alertCodeArray: Array<string>): AlertError {
    const alert = ALERTS.earlyCheckInAlert.find(alertCode => alertCodeArray.includes(alertCode));
    if (!!alert) {
      const origin = this.trip.results[0].segments.entries[0].origin;
      const destination = this.trip.results[0].segments.entries[0].destination;
      return {error: alert, requestData: this.trip, origin: origin, destination: destination};
    }
    return;
  }

  private checkInhibitedAlert(alertCodeArray: Array<string>): AlertError {
    const alert = ALERTS.inhibitiedAlert.find(alertCode => alertCodeArray.includes(alertCode));
    if (!!alert) {
      return { error: alert, requestData: this.trip };
    }
    return;
  }

  private checkAirportAssistanceAlert(alertCodeArray: Array<string>): AlertError {
    const alert = ALERTS.airportAssistanceAlert.find(alertCode => alertCodeArray.includes(alertCode));
    if (!!alert) {
      return { error: alert, requestData: this.trip };
    }
    return;
  }

  private getFirstAlert(alertCodeArray: Array<string>, isInternational: boolean): AlertError {
    const alert = alertCodeArray.shift();
    if (!!alert && !(alert === ALERTS.regulatoryDetailsInhibition && isInternational)) {
      return { error: alert, requestData: this.trip };
    }
    return;
  }
}
